
import { ref, reactive, onMounted } from "vue"
import RenderInstructionService from "../../../../services/RenderInstructionService"
import RiCustomProcessingsForm from "./RiCustomProcessingsForm.vue"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"
import RenderInstructionImport from "@/components/settings/render-instructions/RenderInstructionImport.vue"

export default {
  components: { RiCustomProcessingsForm, SecondaryButton, RenderInstructionImport },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    openImport: {
      type: Boolean,
      default: false,
    },
    orgIdToSelect: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props: any) {
    const service = new RenderInstructionService("ricustomprocessings")
    const additionalFields = reactive({
      processings: "",
    })

    const columns = reactive([])

    const collection = ref()

    onMounted(() => {
      collection.value.riImportDialog = props.openImport
    })

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.riImportDialog = false
      collection.value.reload()
    }

    function showRiImportDialog() {
      collection.value.riImportDialog = true
    }

    return { service, additionalFields, columns, collection, closeDialogs, showRiImportDialog }
  },
}
