
export default {
  name: "LoadingSpinner",
  props: {
    size: Number,
    borderSize: Number,
    color: String,
  },
  setup(props: any) {
    let styleElement = document.head.appendChild(document.createElement("style"))
    styleElement.innerHTML = `.spinner:after {border: ${props.borderSize}px solid ${
      props.color
    }; border-color: ${props.color} transparent ${props.color} transparent;width: ${
      props.size - 16
    }px;height: ${props.size - 16}px;}`
  },
}
