
import ResultSet from "@/models/ResultSet"
import OrganizationService from "@/services/OrganizationService"
import RenderInstructionService from "@/services/RenderInstructionService"
import { computed, reactive, ref } from "vue"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"
import { useToast } from "primevue/usetoast"
import TertiaryButton from "@/components/buttons/TertiaryButton.vue"
import { useRoute, useRouter } from "vue-router"
import LoadingSpinner from "@/components/general/LoadingSpinner.vue"

export default {
  name: "RenderInstructionImport",
  components: { SecondaryButton, TertiaryButton, LoadingSpinner },
  emits: ["reload"],
  props: {
    renderInstructionType: String,
    columns: Object,
    organization: Object,
    orgIdToSelect: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props: any, { emit }: any) {
    const route = useRoute()
    const router = useRouter()
    const organizationService = OrganizationService.getInstance()
    const renderInstructionService = new RenderInstructionService(props.renderInstructionType)
    const selectedOrganization = ref()
    const organizations = reactive([])
    const renderInstructions = reactive([])
    const selectedRenderInstructions = ref()
    const toast = useToast()
    const orderImagingRouteNames = ref([
      { label: "Annotations", value: "organizationAnnotationCollection" },
      { label: "Context Vision", value: "organizationCVInputCollection" },
      { label: "Windowings", value: "organizationWindowingsCollection" },
      { label: "Custom Processings", value: "organizationRiCustomProcessings" },
    ])
    const importing = ref(false)

    const nextRouteObj = computed(() => {
      const currentRoute = route.name
      let nextIndex = 0
      if (
        orderImagingRouteNames.value.findIndex(
          (routeName: any) => routeName.value === currentRoute.toString()
        ) !==
        orderImagingRouteNames.value.length - 1
      ) {
        nextIndex =
          orderImagingRouteNames.value.findIndex(
            (routeName: any) => routeName.value === currentRoute.toString()
          ) + 1
      }
      return orderImagingRouteNames.value[nextIndex]
    })

    loadOrganizations()

    function loadOrganizations() {
      organizationService.getOrganizations(0, "", "", null, 10000).then((result) => {
        organizations.length = 0
        if (result.rows?.length) {
          result.rows.forEach((row: any) => {
            organizations.push(row)
            if (props.orgIdToSelect && row?.id === props.orgIdToSelect) {
              selectedOrganization.value = row
            }
          })
          if (selectedOrganization.value) {
            loadRenderInstructionsOfOrganization()
          }
        }
      })
    }

    function loadRenderInstructionsOfOrganization() {
      renderInstructions.length = 0
      if (selectedOrganization.value?.id) {
        renderInstructionService
          .getRules(
            null,
            null,
            selectedOrganization.value.id,
            null,
            null,
            null,
            null,
            null,
            0,
            "",
            true,
            10000
          )
          .then((result: ResultSet<any>) => {
            selectedRenderInstructions.value = []
            result.rows.map((row: any) => {
              let item = { selected: true, data: row }
              renderInstructions.push(item)
              selectedRenderInstructions.value.push(item)
            })
          })
      }
    }

    function importSelectedRenderInstructions() {
      if (selectedRenderInstructions.value?.length) {
        importing.value = true
        renderInstructionService
          .copy(
            props.organization?.id,
            null,
            null,
            selectedRenderInstructions.value.map((ri: any) => ri.data.id)
          )
          .then(() => {
            selectedRenderInstructions.value = null
            importing.value = false
            emit("reload")
          })
          .catch((error: any) => {
            if (error.message.includes("409")) {
              toast.add({
                severity: "error",
                summary: "Error",
                detail: "The selected render instruction(s) already exists!",
                life: 3000,
              })
            }
            importing.value = false
          })
      }
    }

    function goToNextRiImport() {
      router.push({
        name: nextRouteObj.value.value,
        params: {
          openImport: "true",
          orgIdToSelect: selectedOrganization.value?.id,
          title: nextRouteObj.value.label,
        },
      })
    }

    return {
      selectedOrganization,
      organizations,
      renderInstructions,
      selectedRenderInstructions,
      loadRenderInstructionsOfOrganization,
      importSelectedRenderInstructions,
      goToNextRiImport,
      nextRouteObj,
      importing,
    }
  },
}
