
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"

export default {
  props: {
    additionalFields: { processings: String },
  },
  setup(props: any) {
    const form = {
      processings: {
        processingsRequired: helpers.withMessage("The processings are is required", required),
      },
    }

    const v$ = useVuelidate(form, props.additionalFields)

    return { v$ }
  },
}
